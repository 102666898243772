
const Text = () => {
	return (
		<div id="contact-text">
			<h2 id="hear-from-you">We would love to hear from you!</h2>
			<h1 id="contact-title">Contact Us</h1>
			<p className="contact-text-content">
				We are always happy to hear from our listeners. Please feel free to send
				us your comments, questions, or suggestions any time using this form.
			</p>
			<p className="contact-text-content">
				You can also email us directly by sending an email to
				comments@organlive.com.
			</p>
		</div>
	)
}

export default Text
