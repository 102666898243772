import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { setDocumentTitle } from "../state/ui"

const Schedule = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setDocumentTitle("Organlive | Mobile Apps"))
	}, [dispatch])

	return (
		<div id="schedule">
			<div id="schedule-inner">
				<h1 id="schedule-title">Mobile Apps</h1>
				<div className="schedule-item">
					<p className="schedule-item-text">
						You can take Organlive with you wherever you go with our mobile apps updated for 2023.  You can stream all of our stations, Organlive, Positively Baroque, and the Organ Experience and get complete information about each track playing.
					</p>
				</div>
				<div className="schedule-item">
					<p className="day">Android</p>
					<p className="schedule-item-text">
						Download the Android app from the Play Store:
						 <a
                                                target="_blank"
                                                href="https://play.google.com/store/apps/details?id=com.organ.media.organ_media"
                                                rel="noreferrer"
						class="libraryListOptionsWord pointer hoverOpacity">Google Play
                                        </a>
				</p>
				</div>
					<div className="schedule-item">
                                        <p className="day">iOS</p>
                                        <p className="schedule-item-text">Download the iOS app from the Apple App Store:
						<a
						target="_blank"
						href="https://apps.apple.com/app/organ-live-media-foundation/id1663040569"
						rel="noreferrer"
						class="libraryListOptionsWord pointer hoverOpacity">App Store
					</a>
                                </p>
                                </div>
			</div>
		</div>
	)
}

export default Schedule
